import React, { useEffect } from "react";
import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import BackpackOutlinedIcon from '@mui/icons-material/BackpackOutlined';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Logo from "../../assets/JUNE-LOGO-3.png"
import { Link } from "react-router-dom";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { logout, reset } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);

  const logoutHandler = async () => {
    try {
      await dispatch(logout()).unwrap(); // If logout returns a promise, wait for it to resolve
      dispatch(reset()); // Reset the state
      navigate("/login"); // Navigate to the login page after state reset
    } catch (error) {
      console.error("An error occurred during logout: ", error);
    }
  };


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="Sidebar">
      <div className="logo">
        <Link to="/">
          <img src={Logo} alt="JUNE" />
        </Link>
      </div>
      {/* <hr /> */}
      <div className="center">
        <ul className="dash_links">
          {/* <p className="title">MAIN</p> */}
          <Link to="/admin">
            <li>
              <HomeOutlinedIcon className="icon" />
              <span>Home</span>
            </li>
          </Link>
          {/* <p className="title">LISTS</p> */}
          <Link to="/admin/users">
            <li>
              <GroupOutlinedIcon className="icon" />
              <span>Customers</span>
            </li>
          </Link>
          {/* <Link to="/admin/packages">
            <li>
              <AirplaneTicketOutlinedIcon className="icon" />
              <span>Shipments</span>
            </li>
          </Link> */}
          <Link to="/admin/all-customer-packages">
            <li>
              <BackpackOutlinedIcon className="icon" />
              <span>Purchases</span>
            </li>
          </Link>
          {/* <Link to="/admin/orders">
            <li>
              <CreditCardIcon className="icon" />
              <span>Orders</span>
            </li>
          </Link> */}
          {/* <Link to="/admin/profile">
            <li>
              <AccountCircleOutlinedIcon className="icon" />
              <span> Account</span>
            </li>
          </Link> */}
          <li onClick={logoutHandler}>
            <ExitToAppIcon className="icon" />
            <span>Sign Out</span>
          </li>
        </ul>
      </div>
      

      <div className="bottom">
        <div className="address">
          <p className="title">JUNE ADDRESS</p>
          <p>6858 NW 75th Street, Ste 4</p>
          <p>Miami, FL 33166</p>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;