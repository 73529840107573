import React, { useState } from "react";
import { Button, Modal, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { TextField, Typography, Box } from '@mui/material';
import axios from "axios";
import { useSelector } from "react-redux";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import "./InvoiceUploadModal.css";

const InvoiceUploadModal = ({ productId, closeModal }) => {
  const [fileList, setFileList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [uploading, setUploading] = useState(false);
  const token = useSelector((state) => state.auth.user.accessToken);

  const handleUpload = (info) => {
    const { fileList } = info;
    setFileList(fileList);
  };

  const handleSubmit = () => {
    if (fileList.length === 0) {
      message.error("No files selected.");
      return;
    }

    setUploading(true);

    const uploadPromises = fileList.map((fileItem) => {
      const file = fileItem.originFileObj;
      const storage = getStorage();
      const storageRef = ref(storage, `Invoices/${productId}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setFileList((prevFileList) =>
              prevFileList.map((item) =>
                item.uid === fileItem.uid
                  ? { ...item, status: "uploading", percent: progress }
                  : item
              )
            );
          },
          (error) => {
            setFileList((prevFileList) =>
              prevFileList.map((item) =>
                item.uid === fileItem.uid
                  ? { ...item, status: "error", error: error }
                  : item
              )
            );
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((downloadURL) => {
                const apiUrl = `/api/v1/user/customer/${productId}/upload/preAlert`;
                return axios.put(
                  `${apiUrl}?downloadUrl=${encodeURIComponent(downloadURL)}`,
                  {},
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }
                ).then(() => downloadURL);
              })
              .then((downloadURL) => {
                setFileList((prevFileList) =>
                  prevFileList.map((item) =>
                    item.uid === fileItem.uid
                      ? { ...item, status: "done", url: downloadURL }
                      : item
                  )
                );
                resolve();
              })
              .catch((error) => {
                setFileList((prevFileList) =>
                  prevFileList.map((item) =>
                    item.uid === fileItem.uid
                      ? { ...item, status: "error", error: error }
                      : item
                  )
                );
                reject(error);
              });
          }
        );
      });
    });

    Promise.all(uploadPromises)
      .then(() => {
        message.success("All files uploaded successfully!");
        setUploading(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        message.error("Some files failed to upload. Please try again.");
        setUploading(false);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    closeModal();
  };

  return (
    <Modal
      visible={isModalVisible}
      title="Add Invoice"
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          disabled={fileList.length === 0 || uploading}
          loading={uploading}
        >
          {uploading ? "Uploading" : `Upload ${fileList.length} file${fileList.length !== 1 ? 's' : ''}`}
        </Button>,
      ]}
    >
      <Upload.Dragger
        listType="picture"
        fileList={fileList}
        onChange={handleUpload}
        beforeUpload={() => false}
        multiple={true}
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">Drag and drop invoices here or select to add</p>
        <p className="ant-upload-hint">
You can add multiple invoices        </p>
      </Upload.Dragger>
      {/* {productId && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" component="label" htmlFor="product-id">
            Product ID:
          </Typography>
          <TextField
            id="product-id"
            variant="outlined"
            fullWidth
            size="small"
            value={productId}
            InputProps={{
              readOnly: true,
            }}
            sx={{ mt: 1 }}
          />
        </Box>
      )} */}
    </Modal>
  );
};

export default InvoiceUploadModal;