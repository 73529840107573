import React from "react";
import { Link } from "react-router-dom";
import "./checkEmail.scss";
import EmailIcon from '@mui/icons-material/Email'; // Assuming you're using Material-UI icons

const CheckEmail = () => {
  return (
    <div className="verify-email-container">
      <div className="verify-email-card">
        <EmailIcon className="email-icon" />
        <h1>Verify your email</h1>
        <p className="main-message">Thank you for creating your JUNE account!</p>
        <p>
          Please check your email to verify your account by selecting the
          verification link. If you do not see our email message in your inbox,
          check your spam folder.
        </p>
        <p className="lifestyle-message">
          Once that's done, you're all set to start shipping your lifestyle!
        </p>
        <Link className="sign-in-button" to="/login">
          Go back to Sign In
        </Link>
      </div>
    </div>
  );
};

export default CheckEmail;