import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

function About() {
  return (
    <div>
      About
      <Footer />
    </div>
  );
}

export default About;
