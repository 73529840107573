import * as React from "react";
import { useEffect, useState } from "react";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { register, reset } from "../../../features/auth/authSlice";
import { LinearProgress, InputAdornment, IconButton } from "@mui/material";
import Logo from "../../../assets/JUNE-LOGO-3.png";
import { MuiTelInput } from "mui-tel-input";
import { Modal } from "@mui/material"; // Import the 'Modal' component from the '@mui/material' package
import ReactMarkdown from "react-markdown";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://junelogistics.com/">
        JUNE LOGISTICS
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "filled",
        InputProps: {
          disableUnderline: true,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "9px", // rounded corners for all TextFields
          "&:before": {
            display: "none", // remove underline in unfocused state
          },
          "&:after": {
            display: "none", // Remove underline in focused state
            // removes the green line by making it transparent
          },
          "&.Mui-focused": {
            boxShadow: `0 0 0 1px #000000`, // Black border for focused state
          },
        },
      },
    },
  },
});

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function CustRegister() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showTermsError, setShowTermsError] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [modalStyle] = useState(getModalStyle);
  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordEntered, setPasswordEntered] = useState("");
  const [retypePassword, setRetypePassword] = useState("");

  const [formData, setFormData] = useState({
    firstname: "",
    username: "test",
    lastname: "",
    email: "",
    phoneNumber: "",
    acceptedTermsAndConditions: true,
    password: "",
    referredBy: "", // Add referredBy to the state
  });

  const {
    firstname,
    lastname,
    email,
    username,
    acceptedTermsAndConditions,
    phoneNumber,
    password,
    referredBy,
  } = formData;

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const handlePhoneNumberChange = (value) => {
    // Remove all non-numeric characters for counting digits
    const numericValue = value.replace(/\D/g, "");

    // Check if the number of digits is within the desired limit (e.g., 10)
    if (numericValue.length <= 10) {
      setFormData({ ...formData, phoneNumber: value });
    }
  };

  // useEffect(() => {
  //   if (isSuccess || user) {
  //     setSuccessMessage(
  //       "Account created successfully. Please check your email to verify. If you do not see the email, check your spam."
  //     );
  //   }
  //   dispatch(reset());
  // }, [user, isError, isSuccess, message, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onCheckboxChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      acceptedTermsAndConditions: e.target.checked,
    }));
  };

  const validateFirstName = (firstName) => {
    // Example: First must not be empty
    return firstName.trim() !== "";
  };

  const validateLastName = (lastName) => {
    // Example: Last Name must not be empty
    return lastName.trim() !== "";
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(email.toLowerCase());
  };

  // const validatePhoneNumber = (phoneNumber) => {
  //   // Regular expression for phone number validation (simple version)
  //   const re = /^\d{11}$/;
  //   // const re = /^(?:\+?\d{1,3})?[-\s.]?(?:\((?:\d{1,4})\)|\d{1,4})[-\s.]?\d{1,4}[-\s.]?\d{1,4}[-\s.]?\d{1,9}$/;

  //   // Adjust this regex based on the expected format
  //   return re.test(phoneNumber);
  // };

  const validatePassword = (password) => {
    // Example: Password must be at least 8 characters
    return password.length >= 8;
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setFormData((prevState) => ({
      ...prevState,
      acceptedTermsAndConditions: true, // Automatically accept terms on modal close
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Reset the error messages
    setShowTermsError(false);
    setEmailError("");
    setPhoneError("");
    setPasswordError("");
    setFirstNameError("");
    setLastNameError("");

    let hasError = false;

    // Check if passwords match
    if (passwordEntered !== retypePassword) {
      setPasswordError("Passwords do not match.");
      return;
    }

    if (!acceptedTermsAndConditions) {
      setShowTermsError(true); // Show error message for terms and conditions
      return;
    } else {
      setShowTermsError(false); // Hide error message if terms are accepted
    }

    // Validate First Name
    if (!validateFirstName(firstname)) {
      setFirstNameError("First name is required");
      hasError = true;
    }

    // Validate Last Name
    if (!validateLastName(lastname)) {
      setLastNameError("Last name is required");
      hasError = true;
    }

    // Validate the email
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      hasError = true;
    }

    //  // Validate the phone number
    //  if (!validatePhoneNumber(phoneNumber)) {
    //   setPhoneError('Please enter a valid phone number');
    //   hasError = true;

    // }

    // Validate Password
    if (!validatePassword(password)) {
      setPasswordError("Password must be at least 8 characters long");
      hasError = true;
    }

    // If any validation failed, return early
    if (hasError) {
      return;
    }

    const userData = {
      firstname,
      lastname,
      email,
      username,
      role: "CUSTOMER",
      acceptedTermsAndConditions,
      phoneNumber,
      password,
    };
    try {
      const response = await dispatch(register(userData)).unwrap();
      // Redirect to the "check email" route upon successful registration
      navigate("/customer/register/checkEmail");
    } catch (error) {
      // Make sure error and error.message exist
      if (error && error.message) {
        if (error.message.includes("duplicate email")) {
          setEmailError("This email is already in use");
        }
        if (error.message.includes("duplicate phone number")) {
          setPhoneError("This phone number is already in use");
        }
        // Handle other errors
      }
    }
  };

  const termsMarkdown = `
Welcome to June Logistics! By registering for and using our services, you agree to be bound by the following terms and conditions. Please read them carefully.

**1\. Introduction**

June Logistics provides courier and delivery services accessible via our mobile app and website. These services allow users to send parcels to various destinations. This document outlines the terms under which you agree to use our services.

**2\. User Registration**

To use June Logistics, you must register for an account by providing accurate and complete information as prompted by the registration form. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account.

**3\. Service Use**

1.  You agree to use our services only for lawful purposes and in compliance with our policies.

2.  You are responsible for the content and condition of any parcel you send through our services.

3.  You agree not to send prohibited items as outlined in our Prohibited Items List.

4.  By signing you are consenting to use opening your package to review the package.

**4\. Fees and Payments**

1.  You agree to pay all fees and charges associated with your use of our services.

2.  Prices may vary based on parcel size, weight, and destination.

**5\. Privacy Policy**

Your privacy is important to us. Our Privacy Policy explains how we collect, use, and protect your personal information. By using our services, you agree to the terms of our Privacy Policy.

**6\. Limitation of Liability**

June Logistics is not liable for any damages or losses resulting from the use of our service, except as required by law. Our liability in such cases will be limited to the amount paid for the service.

**7\. Indemnification**

You agree to indemnify and hold harmless June Logistics and its officers, directors, employees, and agents from any claims, damages, and expenses arising from your use of the service.

**8\. Dispute Resolution**

Any disputes related to these terms will be resolved through binding arbitration, except for disputes relating to intellectual property rights.

**9\. Amendments**

We reserve the right to modify these terms and conditions at any time. Your continued use of our services after any amendment signifies your agreement to the changes.

**10\. Termination**

We may terminate or suspend your account and access to our services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms and Conditions.

**11\. Governing Law**

These terms shall be governed by the laws of [Jurisdiction], without regard to its conflict of law provisions.

**12\. Contact Information**

For any questions or concerns about these terms, please contact us at [Contact Information].

By creating an account and using June Logistics, you acknowledge that you have read, understood, and agreed to be bound by these Terms and Conditions.
`;

  const TermsAndConditionsModal = (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box
        sx={{
          ...modalStyle,
          position: "absolute",
          width: "60%", // Adjusted to make the modal wider
          maxWidth: "800px", // Ensures modal is not too wide on large screens
          bgcolor: "background.paper",
          p: 4,
          overflowY: "scroll",
          maxHeight: "90vh",
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
          borderRadius: 2, // Optional: Adds rounded corners
        }}
      >
        <Typography
          id="simple-modal-title"
          variant="h6"
          component="h2"
          gutterBottom
        >
          Terms and Conditions
        </Typography>
        <Typography
          id="simple-modal-description"
          sx={{ mt: 2, whiteSpace: "pre-line" }}
        >
          <ReactMarkdown>{termsMarkdown}</ReactMarkdown>
        </Typography>
        <Box textAlign="center" marginTop={4}>
          <Button onClick={() => setOpenModal(false)} variant="uncontained">
            I Disagree
          </Button>
          <Button
            onClick={handleCloseModal}
            variant="contained"
            color="primary"
          >
            I Agree
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <img src={Logo} alt="" />
          <Typography
            component="h1"
            variant="h5"
            sx={{ transform: "translateY(-100%)" }}
          >
            If you want it, you should have it.
          </Typography>
          <Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="firstName"
                  name="firstname"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  value={firstname}
                  onChange={onChange}
                  autoFocus
                  variant="filled"
                  error={!!firstNameError}
                  helperText={firstNameError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  value={lastname}
                  onChange={onChange}
                  autoComplete="lastName"
                  error={!!lastNameError}
                  helperText={lastNameError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={email}
                  onChange={onChange}
                  autoComplete="email"
                  error={!!emailError}
                  helperText={emailError}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  value={username}
                  onChange={onChange}
                  autoComplete="username"
                />
              </Grid> */}
              <Grid item xs={12}>
                <MuiTelInput
                  required
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phoneNumber"
                  value={phoneNumber}
                  // onChange={onChange}
                  autoComplete="phone-number"
                  variant="filled"
                  // error={!!phoneError}
                  // helperText={phoneError}
                  placeholder="(876) 456-7890" // example placeholder
                  InputProps={{
                    disableUnderline: true,
                  }}
                  onChange={(value) =>
                    setFormData({ ...formData, phoneNumber: value })
                  }
                  error={!!phoneError}
                  helperText={phoneError}
                  defaultCountry="JM" // Set a default country if needed
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ fontSize: "0.6rem" }} // Adjust font size here
                >
                  Please ensure that your phone number is correct. An incorrect
                  phone number could result in your package arrival being
                  delayed.{" "}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(e) => {
                    setPasswordEntered(e.target.value);
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  autoComplete="new-password"
                  variant="filled"
                  error={!!passwordError}
                  helperText={passwordError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="retypePassword"
                  label="Retype Password"
                  type={showPassword ? "text" : "password"}
                  id="retypePassword"
                  autoComplete="new-password"
                  value={retypePassword}
                  onChange={(e) => {
                    setRetypePassword(e.target.value);
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="acceptedTermsAndConditions"
                      color="primary"
                      checked={acceptedTermsAndConditions}
                      onChange={onCheckboxChange}
                    />
                  }
                  label="Accepted Terms And Conditions"
                />
              </Grid> */}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="referredBy"
                label="Enter Referral Code"
                name="referredBy"
                value={referredBy}
                onChange={onChange}
                autoComplete="referredBy"
                variant="filled"
              />
            </Grid>
            <Grid container justifyContent="flex-end">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.acceptedTermsAndConditions}
                    onChange={onCheckboxChange}
                    style={{ visibility: "hidden" }}
                  />
                }
                label={
                  <Typography variant="body2">
                    Read our
                    <Button
                      onClick={handleOpenModal}
                      sx={{ textTransform: "none", transform: "translate(-2%, -2%)" }}
                    >
                      Terms and Conditions
                    </Button>
                  </Typography>
                }
              />
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              sx={{
                mt: 3,
                mb: 2,
                fontSize: "large",
                textTransform: "none",
                height: "50px",
              }}
            >
              {isLoading ? "Creating Account" : "Create Account"}
            </Button>
            {isLoading && <LinearProgress />}

            {successMessage && (
              <Typography
                variant="body2"
                color="text.primary"
                align="center"
                sx={{ mt: 2, mb: 1 }}
              >
                {successMessage}
              </Typography>
            )}

            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link
                  to="/login"
                  // variant="body2"
                  style={{
                    textDecoration: "underline",
                    color: "#1976d2",
                    fontSize: 12,
                  }}
                >
                  Already have an account? SIGN IN
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {isError && message && (
          <div style={{ color: "red", marginTop: "10px" }}>{message}</div>
        )}
        <Copyright sx={{ mt: 5, mb: 4 }} />
      </Container>

      {showTermsError && (
        <Typography color="error" variant="body2">
          You must accept the terms and conditions to proceed.
        </Typography>
      )}
      {TermsAndConditionsModal}
    </ThemeProvider>
  );
}
