import React, { useState, useEffect } from "react";
import "./product.scss";
import NavAppWarehouse from "../../../components/NavAppSidebar/NavAppWarehouse";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { Grid, TextField, Button, Typography, Box, CircularProgress } from "@mui/material";
import FooterNavApp from "../../../WarehouseManagement/FooterNavApp/FooterNavApp";

const customStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: 56,
    borderColor: state.isFocused ? '#40a9ff' : '#d1d1d1',
    boxShadow: state.isFocused ? '0 0 0 2px rgba(24, 144, 255, 0.2)' : 'none',
    '&:hover': {
      borderColor: state.isFocused ? '#40a9ff' : '#40a9ff',
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
};

const Products = () => {
  const { handleSubmit, control, reset, setValue, formState: { errors } } = useForm();
  const [tableData, setTableData] = useState([]);
  const token = useSelector((state) => state.auth.user.accessToken);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const customerResponse = await axios.get(
        "/api/v1/user/customer/get/all",
        config
      );
      setCustomerList(
        customerResponse.data.map((x) => ({
          value: x.customerNumber,
          label: `${x.user.firstName} ${x.user.lastName} - ${x.customerNumber}`,
          email: x.user.emailAddress,
        }))
      );

      const productResponse = await axios.get(
        "/api/v1/user/warehouse/get/all/products",
        config
      );
      setTableData(productResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 403) {
        console.log(error);
      }
    }
  };

  const initialWeightOptions =
    JSON.parse(localStorage.getItem("weightOptions")) ||
    Array.from({ length: 50 }, (_, i) => ({
      value: `${i + 1}`,
      label: `${i + 1}`,
    }));

  const [weightOptions, setWeightOptions] = useState(initialWeightOptions);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem("weightOptions", JSON.stringify(weightOptions));
  }, [weightOptions]);

  const [supplierOptions, setSupplierOptions] = useState([]);

  useEffect(() => {
    const storedSuppliers = localStorage.getItem("suppliers");
    if (storedSuppliers) {
      setSupplierOptions(JSON.parse(storedSuppliers));
    } else {
      setSupplierOptions(
        [
          "Amazon", "eBay", "Walmart", "Target", "Best Buy", "Home Depot", "Etsy",
          "ASOS", "Boohoo", "Shein", "Zalando", "Farfetch", "B&H Photo Video",
          "Newegg", "Apple", "Samsung", "Society6", "Redbubble", "Uncommon Goods",
          "Rakuten", "Wildberries", "Ozon", "Flipkart", "Wish",
        ].map((place) => ({ value: place, label: place }))
      );
    }
  }, []);

  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomer(selectedOption);

    if (selectedOption && selectedOption.label) {
      const [fullName, customerNumber] = selectedOption.label.split(" - ");
      const [firstName, ...lastNameParts] = fullName.split(" ");
      const lastName = lastNameParts.join(" ");
      setValue("customerEmail", selectedOption.email);
      setValue("customerNumber", customerNumber);
      setValue("firstName", firstName);
      setValue("lastName", lastName);
    }
  };

  const handleSupplierCreate = (inputValue) => {
    const newSupplierOption = { value: inputValue, label: inputValue };

    setValue("supplierName", inputValue);

    setSupplierOptions([...supplierOptions, newSupplierOption]);

    localStorage.setItem(
      "suppliers",
      JSON.stringify([...supplierOptions, newSupplierOption])
    );
  };

  const handleWeightChange = (newValue, actionMeta) => {
    setValue("weight", newValue ? newValue.value : "");

    if (actionMeta.action === "create-option") {
      const updatedOptions = [
        ...weightOptions,
        { value: newValue.value, label: newValue.label },
      ];
      updatedOptions.sort((a, b) => parseInt(a.value) - parseInt(b.value));
      setWeightOptions(updatedOptions);
      localStorage.setItem("weightOptions", JSON.stringify(updatedOptions));
    }
  };

  const onSubmit = async (formData) => {
    console.log("Submit started, setting isLoading to true");
    setIsLoading(true);
    const data = {
      ...formData,
      weight: formData.weight?.value || formData.weight,
      supplierName: formData.supplierName?.value || formData.supplierName,
    };

    console.log("Data being sent:", JSON.stringify(data, null, 2));

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      console.log("Product data before submission:", data);

      const postResponse = await axios.post(
        "/api/v1/user/warehouse/create/product",
        data,
        config
      );

      console.log("Response from server:", postResponse.data);

      const productResponse = await axios.get(
        "/api/v1/user/warehouse/get/all/products",
        config
      );

      console.log("Fetched product data after GET:", productResponse.data);

      const lastItemId = getLastItemId(productResponse.data);

      toLabel(
        lastItemId,
        data.supplierName,
        data.description,
        data.weight,
        data.firstName,
        data.lastName,
        data.customerNumber
      );
    } catch (error) {
      console.error("Error: ", error);
      if (error.response) {
        console.log("Error Response Data:", error.response.data);
        console.log("Error Response Status:", error.response.status);
        console.log("Error Response Headers:", error.response.headers);
      } else if (error.request) {
        console.log("Error Request:", error.request);
      } else {
        console.log("Error Message:", error.message);
      }
    } finally {
      console.log("Submit finished, setting isLoading to false");
      setIsLoading(false);
    }

    reset();
    setSelectedCustomer(null);
  };

  const toLabel = (productId, supplierName, description, weight, firstName, lastName, customerNumber) => {
    navigate("/warehouse/dash/shipments/label/", {
      state: { id: productId, supplier: supplierName, description: description, weight: weight, firstName: firstName, lastName: lastName, customerNumber: customerNumber },
    });
  };

  const getLastItemId = (productData) => {
    if (!productData || productData.length === 0) {
      return null;
    }

    const lastProduct = productData[productData.length - 1];
    return lastProduct.id;
  };

  console.log("Current isLoading state:", isLoading);

  return (
    <div className="new_products">
      <NavAppWarehouse />
      <div className="product_container">
        <div className="top">
          <h1>ADD PACKAGE</h1>
        </div>

        <div className="bottom">
          <div className="right">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Supplier Name</Typography>
                  <Controller
                    name="supplierName"
                    control={control}
                    rules={{ required: "Supplier Name is required" }}
                    render={({ field }) => (
                      <CreatableSelect
                        {...field}
                        options={supplierOptions}
                        onChange={(selectedOption) => field.onChange(selectedOption)}
                        onCreateOption={handleSupplierCreate}
                        isSearchable
                        value={supplierOptions.find(option => option.value === field.value)}
                        placeholder="Select or create a Supplier"
                        styles={customStyles}
                      />
                    )}
                  />
                  {errors.supplierName && (
                    <Typography color="error" align="left" variant="caption" sx={{ mt: 1 }}>{errors.supplierName.message}</Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Weight</Typography>
                  <Controller
                    name="weight"
                    control={control}
                    rules={{ required: "Weight is required" }}
                    render={({ field }) => (
                      <CreatableSelect
                        {...field}
                        options={weightOptions}
                        onChange={handleWeightChange}
                        isClearable
                        isSearchable
                        value={weightOptions.find(option => option.value === field.value)}
                        placeholder="Select or create a Weight"
                        styles={customStyles}
                      />
                    )}
                  />
                  {errors.weight && (
                    <Typography color="error" align="left" variant="caption" sx={{ mt: 1 }}>{errors.weight.message}</Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Customer</Typography>
                  <Controller
                    name="customerEmail"
                    control={control}
                    rules={{ required: "Customer Name/ID is required" }}
                    render={({ field }) => (
                      <CreatableSelect
                        {...field}
                        options={customerList}
                        onChange={(selectedOption) => {
                          handleCustomerChange(selectedOption);
                          field.onChange(selectedOption?.email);
                        }}
                        isSearchable
                        value={customerList.find(option => option.email === field.value)}
                        placeholder="Enter Customer Name or ID"
                        styles={customStyles}
                      />
                    )}
                  />
                  {errors.customerEmail && (
                    <Typography color="error" align="left" variant="caption" sx={{ mt: 1 }}>{errors.customerEmail.message}</Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">TID</Typography>
                  <Controller
                    name="trackingNumber"
                    control={control}
                    rules={{ required: "Tracking ID is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        placeholder="Enter Tracking ID"
                        fullWidth
                        variant="outlined"
                        error={!!errors.trackingNumber}
                        helperText={errors.trackingNumber ? errors.trackingNumber.message : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Description</Typography>
                  <Controller
                    name="description"
                    control={control}
                    rules={{ required: "Description is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        minRows={4}
                        multiline
                        placeholder="Enter Description"
                        fullWidth
                        variant="outlined"
                        error={!!errors.description}
                        helperText={errors.description ? errors.description.message : ''}
                      />
                    )}
                  />
                </Grid>
              </Grid>
  
              <Box display="flex" justifyContent="space-between" mt={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "ADD TO LIST"
                  )}
                </Button>

                <Link to="viewProducts" 
                style={{ textDecoration: 'none', pointerEvents: isLoading ? 'none' : 'auto' }}>
                  <Button 
                    variant="outlined" 
                    disabled={isLoading}
                  >
                    VIEW MANIFEST
                  </Button>
                </Link>
              </Box>
            </form>
          </div>
        </div>
      </div>
      {/* <BottomBar /> */}
    </div>
  );
};

export default Products;
