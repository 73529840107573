import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  Grid,
  Typography,
  Paper,
  CircularProgress,
  IconButton
} from "@mui/material";
import { Inbox, LocalShipping, Loyalty } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useFetchUserInformation } from "./FetchUserInformation";
import AccountInformation from "./AccountInformation";
import OverseasWarehouseAddresses from "./OverseasWarehouseAddresses";
import axios from "axios";
import jwt_decode from "jwt-decode";
import "./userInfo.scss";
import { IconTriangles } from "@tabler/icons-react";
import CustImg from "../../assets/june-lady.png";

const UserInfo = () => {
  const token = useSelector((state) => state.auth.user.accessToken);
  const [userData, setUserData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { userAccount, loading, error } = useFetchUserInformation();
  const [monthlyStats, setMonthlyStats] = useState({});

  const calculateMonthlyStats = (data) => {
    const stats = {};
    data.forEach((product) => {
      const date = new Date(product.createdOn);
      const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;

      if (!stats[monthYear]) {
        stats[monthYear] = { count: 0, weight: 0, deliveredCount: 0 };
      }

      stats[monthYear].count += 1;
      stats[monthYear].weight += parseFloat(product.weight) || 0;

      // Increment for all statuses except "DELIVERED" and "PICKED_UP"
      if (
        product.packageStatus &&
        product.packageStatus !== "DELIVERED" &&
        product.packageStatus !== "PICKED_UP"
      ) {
        stats[monthYear].deliveredCount += 1;
      }
    });
    return stats;
  };

  const fetchData = async () => {
    if (userData && userData.customer) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          "/api/v1/user/warehouse/get/all/products",
          config
        );
        const filteredData = response.data.filter(
          (product) =>
            product.customerNumber === userData.customer.customerNumber
        );

        // Set the filtered data to tableData
        setTableData(filteredData);
        // Calculate monthly stats
        setMonthlyStats(calculateMonthlyStats(filteredData));
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (token) {
      const decodedToken = jwt_decode(token);
      axios
        .get(`/api/v1/user/get/by/email?email=${decodedToken.userEmail}`)
        .then((response) => {
          setUserData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching user data: ", error);
        });
    }
  }, [token]);

  useEffect(() => {
    if (userData && userData.customer) {
      fetchData();
    }
  }, [userData]);

  const getCurrentMonthStats = () => {
    const now = new Date();
    const currentMonthYear = `${now.getMonth() + 1}-${now.getFullYear()}`;
    return (
      monthlyStats[currentMonthYear] || {
        count: 0,
        weight: 0,
        deliveredCount: 0,
      }
    );
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  if (loading) {
    return (
      <Container
        maxWidth="lg"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!userAccount) {
    return <Typography>No user account data available.</Typography>;
  }

  return (
    <Container maxWidth="lg">
      <div className="header">
        <div className="name-header">
          <span>
            {/* Greeting Text */}
            <Typography
              variant="h4"
              sx={{
                // paddingLeft: {
                //   xs: "10px", // Small padding for extra-small screens
                //   sm: "30px", // Medium padding for small screens
                //   md: "70px", // Larger padding for medium and larger screens
                // },
                fontWeight: "bold", // Make "Hi" bold
                fontSize: {
                  xs: "2rem", // Smaller font size for phones
                  sm: "2rem", // Slightly larger for tablets
                  md: "2.25rem", // Default size for medium and larger screens
                },
                display: "block", // Ensures it’s treated as a block element
                transform: "translateY(-30px)", // Move it up slightly
              }}
            >
              Hi {userAccount.customer.user.firstName}!
            </Typography>

            {/* Remember Text */}
            <Typography
              variant="h6" // Smaller text size
              sx={{
                // paddingLeft: {
                //   xs: "10px", // Small padding for extra-small screens
                //   sm: "30px", // Medium padding for small screens
                //   md: "70px", // Larger padding for medium and larger screens
                // },
                fontWeight: "bold",
                transform: "translateY(-25px)", // Move it up slightly
                fontStyle: "italic", // Optional: Make it italic for emphasis
                fontSize: {
                  xs: "1rem", // Smaller font size for phones
                  sm: "1rem", // Slightly larger for tablets
                  md: "1.25rem", // Default size for medium and larger screens
                },
              }}
            >
              If you want it, you should have it.
            </Typography>
            {/* Greeting Text */}
            <Typography
              variant="h6"
              sx={{
                // paddingLeft: {
                //   xs: "10px", // Small padding for extra-small screens
                //   sm: "30px", // Medium padding for small screens
                //   md: "70px", // Larger padding for medium and larger screens
                // },
                display: "block", // Ensures it’s treated as a block element
                transform: "translateY(-15px)", // Move it up slightly
                fontSize: {
                  xs: "0.8rem", // Smaller font size for phones
                  sm: "1rem", // Slightly larger for tablets
                  md: "1rem", // Default size for medium and larger screens
                },
                paddingBottom: 2,
              }}
            >
              Referral Code: {userAccount.customer.customerNumber}
              <IconButton
                onClick={() =>
                  copyToClipboard(userAccount.customer.customerNumber)
                }
                sx={{ marginLeft: 0, color: "gray" }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Typography>
          </span>
        </div>
        <img src={CustImg} alt="Customer" className="customer-image" />
      </div>

      <div className="container-wrapper">
        <div className="overview">
          <Grid container spacing={2} justifyContent="center">
            {/* Total Packages */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                className="overview-item"
                sx={{
                  backgroundColor: "black",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 2,
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  {/* Icon */}
                  <Inbox sx={{ marginRight: 1, fontSize: "2rem" }} />
                  {/* Large, Bold Number */}
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ fontWeight: "bold", fontSize: "2rem" }}
                  >
                    {tableData.length}
                  </Typography>
                </div>
                {/* Label below the number */}
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ fontSize: "0.875rem", marginTop: 1, color: "white" }}
                >
                  Total Packages Shipped
                </Typography>
              </Paper>
            </Grid>

            {/* Total Delivered */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                className="overview-item"
                sx={{
                  backgroundColor: "lightgray",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 2,
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "black",
                  }}
                >
                  {/* Icon */}
                  <LocalShipping sx={{ marginRight: 1, fontSize: "2rem" }} />
                  {/* Large, Bold Number */}
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ fontWeight: "bold", fontSize: "2rem" }}
                  >
                    {getCurrentMonthStats().deliveredCount}
                  </Typography>
                </div>
                {/* Label below the number */}
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ fontSize: "0.875rem", marginTop: 1, color: "black" }}
                >
                  Packages on the way
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                className="overview-item"
                sx={{
                  backgroundColor: "#00fd3d",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 2,
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "black",
                  }}
                >
                  {/* Icon */}
                  <Loyalty sx={{ marginRight: 1, fontSize: "2rem" }} />
                  {/* Large, Bold Number */}
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ fontWeight: "bold", fontSize: "2rem" }}
                  >
                    {0}
                  </Typography>
                </div>
                {/* Label below the number */}
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ fontSize: "0.875rem", marginTop: 1, color: "black" }}
                >
                  June Points
                </Typography>
              </Paper>
            </Grid>

            {/* Pyramid */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                className="overview-item"
                sx={{
                  backgroundColor: "#ffffff",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 2,
                  textAlign: "center",
                  position: "relative",
                }}
              >
                {/* Overlay
              <div
                style={{
                  position: "absolute", // Position the overlay absolutely within the container
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.05)", // Dim the background with a semi-transparent layer
                  display: "flex",
                  justifyContent: "center", // Center the text horizontally
                  alignItems: "center", // Center the text vertically
                  color: "white", // White text color
                  fontSize: "1rem", // Font size for "COMING SOON"
                  fontWeight: "bold", // Bold text
                  zIndex: 1, // Ensure the overlay stays on top of other content
                }}
              >
                COMING SOON
              </div> */}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "black",
                  }}
                >
                  {/* Icon */}
                  <IconTriangles sx={{ marginRight: 1, fontSize: "2rem" }} />
                  {/* Large, Bold Number */}
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ fontWeight: "bold", fontSize: "2rem" }}
                  >
                    {300}
                  </Typography>
                </div>
                {/* Label below the number */}
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ fontSize: "0.875rem", marginTop: 1, color: "black" }}
                >
                  June Pyramid coming soon
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={2} sx={{ marginTop: 3.5 }}>
          <Grid item xs={12}>
            {/* Shipping Text */}
            <Typography
              variant="h5"
              className="shipping-heading"
              sx={{
                transform: {
                  xs: "translate(0%, 35%)",
                  sm: "translate(5%, 60%)",
                },
                fontWeight: "bold",
              }}
            >
              My Shipping Addresses
            </Typography>
            <OverseasWarehouseAddresses
              userAccount={userAccount}
              loading={loading}
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default UserInfo;
