import React, { useState, useEffect } from 'react';
import { quotes } from './quotes';

const InspirationQuote = () => {
  const [quote, setQuote] = useState('');

  useEffect(() => {
    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    setQuote(randomQuote);
  }, []);

  return (
    <div className="inspiration-quote">
      <p>"{quote}"</p>
    </div>
  );
};

export default InspirationQuote;