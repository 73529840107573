import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import axios from 'axios';

export const useFetchUserInformation = () => {
  const auth = useSelector(state => state.auth);
  const [userAccount, setUserAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserAccount = async () => {
      if (auth.user && auth.user.accessToken) {
        try {
          const decodedToken = jwt_decode(auth.user.accessToken);
          const response = await axios.get(`/api/v1/user/get/by/email?email=${decodedToken.userEmail}`);
          setUserAccount(response.data);
        } catch (error) {
          console.error("Error fetching user data:", error);
          setError("Failed to fetch user data. Please try again later.");
        }
      } else {
        setError("User is not authenticated. Please log in.");
      }
      setLoading(false);
    };

    fetchUserAccount();
  }, [auth.user]);

  return { userAccount, loading, error };
};