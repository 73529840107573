//Sameple orderDetails for customer
import React, { useState, useEffect } from "react";
import "./OrderDetails.css"; // This is where the styling is imported from
import Sidebar from "../customers/sidebar/Sidebar";
import Topbar from "../topbar/Topbar";
import BottomBar from "../customers/bottombar/BottomBar";
import { useSelector } from "react-redux";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";


function OrderDetails() {
  const items = [
    {
      tracking: "MIA00038181709",
      status: "Delivered",
      quantity: "1 of 1",
      amount: "$129.00",
    },
    // ... more items
  ];
  const token = useSelector((state) => state.auth.user.accessToken);
  const [userData, setUserData] = useState(null);
  const [packagesData, setPackagesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


      useEffect(() => {
        if (token) {
          const decodedToken = jwt_decode(token);
          axios
            .get(`/api/v1/user/get/by/email?email=${decodedToken.userEmail}`)
            .then((response) => {
              setUserData(response.data);
            })
            .catch((error) => {
              console.error("Error fetching user data: ", error);
            });
        }
      }, [token]);

      useEffect(() => {
        if (userData && userData.customer) {
          fetchData();
        }
      }, [userData]);

      const fetchData = async () => {
        if (userData && userData.customer) {
          try {
            const config = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };
            const response = await axios.get(
              "/api/v1/user/warehouse/get/all/products",
              config
            );
            const filteredData = response.data.filter(
              (product) =>
                product.customerNumber === userData.customer.customerNumber
            );
            setPackagesData(filteredData);
            setIsLoading(false);
          } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
          }
        }
      };
  return (
    <div className="customer_dash">
      <Sidebar />
      <div className="customer_container">
        <Topbar />
        <div className="deliveries">
          {isLoading ? ( // Display loading indicator when isLoading is true
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress style={{ color: "green" }} />
            </div>
          ) : (
            // Render packagesData when loading is false
            <>
              {packagesData.filter(
                (packageItem) => packageItem.packageStatus === "DELIVERED"
              ).length > 0 ? (
                packagesData
                  .filter(
                    (packageItem) => packageItem.packageStatus === "DELIVERED"
                  )
                  .map((packageItem) => (
                    <Box key={packageItem.id} className="package_tweet">
                      <div className="package_header">
                        <p className="package_description">
                          {packageItem.description}
                        </p>

                        <span className="package_delivery_date">
                          {packageItem.supplierName}
                        </span>

                        <span className="delivered">
                          {packageItem.packageStatus}
                        </span>
                      </div>
                      <div>
                        {/* <button
                className={`uploadButton ${
                  packageItem.preAlert ? "uploaded" : ""
                }`}
              >
                {packageItem.preAlert ? "Invoice Uploaded" : "Upload Invoice"}
              </button> */}
                      </div>
                    </Box>
                  ))
              ) : (
                <div className="no-pkg"> No Packages Delivered</div>
              )}
            </>
          )}
        </div>
      </div>
      <BottomBar />
    </div>
  );
}

export default OrderDetails;
