import React, { useState, useEffect } from "react";
import "./sideBar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeIcon from '@mui/icons-material/Home';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Logo from "../../../assets/JUNE-LOGO-3.png";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { logout, reset } from "../../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import axios from "axios";


const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const { isAuthenticated } = useSelector((state) => state.auth);
  
  const token = useSelector((state) => state.auth.user?.accessToken ?? "");


  useEffect(() => {
    if (token) {
      //Decode the JWT token
      const decodedToken = jwt_decode(token);


      //Make a GET request to fetch user data by email
      axios
        .get(`/api/v1/user/get/by/email?email=${decodedToken.userEmail}`)
        .then((response) => {
          const userDataFromApi = response.data;
          setUserData(userDataFromApi);
        })
        .catch((error) => {
          console.error("Error fetching user data: ", error);
        });
    }
  }, [token]);

  const logoutHandler = async () => {
    try {
      await dispatch(logout()).unwrap();
      await dispatch(reset());
      // After successful logout, then navigate to login or do a window.location.href if you want a hard refresh.
      navigate("/login");
    } catch (error) {
      console.error("An error occurred during logout: ", error);
    }
  };


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="Sidebar">
      <div className="logo">
        <Link to="/">
          <img src={Logo} alt="JUNE" />
        </Link>
      </div>
      {/* <hr /> */}
      <div className="center">
        <ul className="dash_links">
          {/* <p className="title">MAIN</p> */}
          <Link to="/customer/dash">
            <li>
              <HomeIcon className="icon" />
              <span>Home</span>
            </li>
          </Link>
          {/* <p className="title">LISTS</p> */}
          <Link to="/customer/dash/invoices">
            <li>
              <ReceiptIcon className="icon" />
              <span>Packages</span>
            </li>
          </Link>
          {/* <Link to="/customer/dash/table">
            <li>
              <ReceiptIcon className="icon" />
              <span>Table Iteration</span>
            </li>
          </Link> */}
          {/* <Link to="/customer/dash/orders">
            <li>
              <LocalShippingIcon className="icon" />
              <span>Deliveries</span>
            </li>
          </Link>
         */}
          <Link to="/customer/dash/profile">
            <li>
              <AccountCircleOutlinedIcon className="icon" />
              <span> Account</span>
            </li>
          </Link>
          <li onClick={logoutHandler}>
            <ExitToAppIcon className="icon" />
            <span>Sign Out</span>
          </li>
        </ul>
      </div>
    

      <div className="bottom">
        <div className="address">
          <p className="title">Shipping by air</p>
          <p>{userData ? userData.customer.user.firstName: ""} {userData ? userData.customer.user.lastName: ""}</p>
          <p>6858 NW 75th Street, Ste 4</p>
          <p>{userData ? userData.customer.customerNumber : ""}</p>
          <p>Miami, FL 33166</p>
        </div>
      </div>



      <div className="bottom">
        <div className="address">
          <p className="title">Shipping by sea</p>
          <p>JUNE LOGISTICS - {userData ? userData.customer.user.firstName: ""} {userData ? userData.customer.user.lastName: ""} </p>
          <p>7185 NW 87TH AVE</p>
          <p>  Miami Florida 33178</p>
          <p>{userData ? userData.customer.customerNumber : ""}</p>
          <p>Miami, FL 33178-1505</p>
        </div>
      </div>
    </div>
  );
};
export default Sidebar; 