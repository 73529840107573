import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  LinearProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Define the default theme
const defaultTheme = createTheme();

// CSS styles for underlining links
const linkStyle = {
  textDecoration: "underline",
};


export default function PasswordReset() {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState(""); // State for the new password
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [retypePassword, setRetypePassword] = useState(""); // State for retyping the password

const handleSubmit = async (e) => {
  e.preventDefault();

  // Check if passwords match
  if (newPassword !== retypePassword) {
    setMessage("Passwords do not match.");
    return;
  }

  setIsLoading(true);

  try {
    // Construct query parameters
    const queryParams = new URLSearchParams({
      email: email, // the email entered by the user
      password: newPassword, // the new password entered by the user
      retypePassword: retypePassword, // the retyped password
    });

    // Update the password using the provided email, password, and retypePassword
    // Append the query parameters to the URL
    await axios.put(
      `/api/v1/auth/forgot/password?${queryParams.toString()}`
    );

    setMessage(
      "Password reset successful."
    );
  } catch (error) {
    setMessage(
      "Password reset failed: " +
        (error.response?.data.message || error.message)
    );
    console.error("Error:", error);
  }

  setIsLoading(false);
};

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <EmailIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
            onSubmit={handleSubmit}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type={showPassword ? "text" : "password"}
              id="newPassword"
              autoComplete="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="retypePassword"
              label="Retype New Password"
              type={showPassword ? "text" : "password"}
              id="retypePassword"
              autoComplete="new-password"
              value={retypePassword}
              onChange={(e) => setRetypePassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {isLoading ? "Resetting..." : "Reset Password"}
            </Button>
            {isLoading && <LinearProgress />}
            <Grid container>
              <Grid item xs>
                <Link to="/login" variant="body2">
                  Back to login
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* Change the alert style based on the message type */}
        {message && (
          <div
            className={`alert ${
              message.includes("successful") ? "alert-success" : "alert-danger"
            }`}
            role="alert"
          >
            {typeof message === "string" ? message : <>{message}</>}
            {typeof message === "string" &&
              message.startsWith("Password reset successful") && (
                <>{" Please "}
                  <Link to="/login" variant="body2" style={linkStyle}>
                    click here to login
                  </Link>
                  {" with your new password."}
                </>
              )}
          </div>
        )}
      </Container>
    </ThemeProvider>
  );
}
