import React, { useState, useEffect } from "react";
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "./NavMenuTheme.scss";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import axios from "axios";


const theme = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        // This targets the root class of the ListItem component
        // Existing styles...
        "&:after": {
          // If the vertical line is a pseudo-element
          backgroundColor: "#f5f5f5", // Change the color
          // ...other properties if necessary
        },
      },
    },
  },
});

const NavMenu = () => {
  const [userData, setUserData] = useState(null);

  const token = useSelector((state) => state.auth.user?.accessToken ?? "");

  useEffect(() => {
    if (token) {
      //Decode the JWT token
      const decodedToken = jwt_decode(token);


      //Make a GET request to fetch user data by email
      axios
        .get(`/api/v1/user/get/by/email?email=${decodedToken.userEmail}`)
        .then((response) => {
          const userDataFromApi = response.data;
          setUserData(userDataFromApi);
        })
        .catch((error) => {
          console.error("Error fetching user data: ", error);
        });
    }
  }, [token]);

  return (
    <ThemeProvider theme={theme}>
      <Paper className="navmenu">
        <Button variant="outline" color="primary" className="subscribe-button">
          Subscribe to JUNE+
        </Button>
        <List component="nav" className="trends-list">
          {/* Example trends */}
          <ListItem button>
            <div style={{ textAlign: "left", fontSize: 12 }}>
              {" "}
              {/* Ensure alignment */}
              {/* Bold title for the address */}
              <Typography
                variant="TITLE"
                component="div"
                sx={{ fontWeight: "bold", fontSize: "20px" }}
              >
                JUNE ADDRESS
              </Typography>
              {/* Address details */}
              <Typography variant="BODY" color="text.secondary">
                6858 NW 75th Street, Ste 4 <br />{" "}
                {userData ? userData.customer.customerNumber : ""}
                <br />
              </Typography>
              <Typography variant="BODY" color="text.secondary">
                Miami, FL 33166
              </Typography>
            </div>

            {/* <ListItemText primary="6858 NW 75th Street" />
          <ListItemText primary="Ste 4," />
          <ListItemText primary="Miami, FL 33166" /> */}
          </ListItem>
          {/* Repeat for other trends */}
        </List>
      </Paper>
    </ThemeProvider>
  );
};

export default NavMenu;
