import React, { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Assuming you're using Material-UI icons
import "./verifyEmail.scss"
import { Typography, Paper } from "@mui/material";

const VerifyEmail = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    updateDatabase(token);
  }, []);

  const updateDatabase = (token) => {
    axios
      .put(`/api/v1/auth/email/verification?token=${token}`)
      .then((response) => {
        console.log("Database updated successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error updating database:", error);
      });
  };

  return (
    <div className="verify-email-container">
      <Paper className="verify-email-card" 
          sx={{
            // elevation: 0,
          }}
      >
        <CheckCircleIcon className="success-icon" />
        <Typography 
        
        variant="h1"
        fontWeight={1000}
        
        >
          
          Verified!

        </Typography>
        <Typography>Your account is now active! Well done!
          
        </Typography>
        <Link className="sign-in-button" to="/login">
        SIGN IN
        </Link>
      </Paper>
    </div>
  );
};

export default VerifyEmail;