import React from "react";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Skeleton,
  IconButton,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import bySea from "../../assets/juneship.png";
import byAir from "../../assets/juneplane.png";

const OverseasWarehouseAddressSkeleton = () => (
  <Paper
    elevation={0}
    sx={{
      marginBottom: 2,
      padding: 2,
      // border: '1px solid #e0e0e0'
    }}
  >
    <Skeleton variant="text" width="100%" height={40} sx={{ mb: 2 }} />
    {[...Array(4)].map((_, index) => (
      <Skeleton key={index} variant="text" width="100%" />
    ))}
  </Paper>
);

const OverseasWarehouseAddress = ({ title, address, onCopy, image }) => {
  const fullAddress = address.join("\n");

  return (
    <Paper
      elevation={0}
      sx={{
        marginBottom: 2,
        padding: 2,
        border: "1px solid #e0e0e0", // Light grey border for each address
        borderRadius: 2, // Optional: Add border radius for rounded corners
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            backgroundColor: "black", // Black background for the image section
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2, // Optional: Adjust padding for spacing inside the black section
            flex: {
              xs: "0 0 30%", // Half width on mobile
              sm: "0 0 auto", // Revert to normal size on larger screens
            },
            borderRadius: 2, // Optional: Add border radius for rounded corners
          }}
        >
          <img
            src={image}
            alt="Customer"
            style={{
              width: "7em",
              objectFit: "cover",
            }}
          />
        </Box>

        <Box sx={{ marginLeft: 3 }}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: {xs: "1.3rem", sm: "1.7rem"} }}>
            {title}
          </Typography>
          <Box>
            {address.map((line, index) => (
              <Typography key={index} variant="body2">
                {line}
              </Typography>
            ))}
          </Box>
        </Box>
        <IconButton
          onClick={() => onCopy(fullAddress)}
          sx={{ top: 8, right: 8, color: "lightgray", marginLeft: {xs: 1.5, sm: 10} }}
        >
          <ContentCopyIcon />
        </IconButton>
      </Box>
    </Paper>
  );
};

const OverseasWarehouseAddresses = ({ userAccount, loading, onCopy }) => {
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Address copied to clipboard");
        // You can add any other logic here that you want to happen after copying
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  if (loading) {
    return (
      <Grid
        container
        spacing={2}
        sx={{ border: "1px solid #e0e0e0", padding: 2 }}
      >
        <Grid item xs={12} sx={{ padding: 1 }}>
          <OverseasWarehouseAddressSkeleton />
        </Grid>
        <Grid item xs={12} sx={{ padding: 1 }}>
          <OverseasWarehouseAddressSkeleton />
        </Grid>
      </Grid>
    );
  }

  if (!userAccount || !userAccount.customer) {
    return <Typography>No shipping addresses available.</Typography>;
  }

  const airAddress = [
    `${userAccount.customer.user.firstName} ${userAccount.customer.user.lastName}`,
    "6858 NW 75th Street, Ste 4",
    userAccount.customer.customerNumber,
    "Miami, FL 33166",
  ];

  const seaAddress = [
    `JUNE LOGISTICS - ${userAccount.customer.user.firstName} ${userAccount.customer.user.lastName}`,
    "7185 NW 87TH AVE",
    "Miami Florida 33178",
    userAccount.customer.customerNumber,
    "Miami, FL 33178-1505",
  ];

  return (
    <Grid
      container
      spacing={2}
      // sx={{
      //   // border: '1px solid #e0e0e0',
      //   width: {
      //     xs: "90vw", // For small screens (mobile), set width to 80vw (or your desired value)
      //     md: "58.5vw",
      //   },
      //   transform: { xs: "translate(0%, 0%)", sm: "translate(2.5%, 0%)" },
      //   padding: 2,
      //   marginBottom: 10,
      // }}
      className="overseas-warehouse-addresses"
      sx={{
        width:{xs: "93vw", md: "95%"},
        transform: { xs: "translate(1.2%, 0%)", sm: "translate(3.7%, 0%)" },
        padding: 2,
        marginBottom: 10,
        marginTop: 3,
      }}
    >
      <Grid item xs={12} sx={{ padding: 1 }}>
        <OverseasWarehouseAddress
          title="Shipping by air"
          address={airAddress}
          onCopy={handleCopy}
          image={byAir}
        />
      </Grid>
      <Grid item xs={12} sx={{ padding: 1 }}>
        <OverseasWarehouseAddress
          title="Shipping by sea"
          address={seaAddress}
          onCopy={handleCopy}
          image={bySea}
        />
      </Grid>
    </Grid>
  );
};

export default OverseasWarehouseAddresses;
