import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useMediaQuery from '@mui/material/useMediaQuery';

import "./ViewAllProductsTable.scss"; 

import { CircularProgress, Button, Box, Menu, MenuItem, FormControl, Select, Typography, TextField } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import BottomBar from "../bottombar/BottomBar";
import NavAppManagement from "../../../components/NavAppSidebar/NavAppManagement";
import Sidebar from "../../sidebar/Sidebar";


const ViewAllAdminProductTable = ({ filterIds }) => {
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStatusMap, setSelectedStatusMap] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  // const token = useSelector((state) => state.auth.user.accessToken);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const user = useSelector((state) => state.auth.user);
  const token = user ? user.accessToken : null;

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  const fetchData = async () => {
    if (!token) {
      setIsLoading(false);
      return;
    }
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      console.log("Fetching products from:", "/api/v1/user/warehouse/get/all/products");

      const response = await axios.get("/api/v1/user/warehouse/get/all/products", config);

      console.log("Response received:", response.status);


      let data = response.data;

      // Filter data based on filterIds if provided
      if (filterIds && filterIds.length > 0) {
        data = data.filter((item) => filterIds.includes(item.junId));
      }

      // Sort data by dateAdded from most recent to least recent
      data.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded));

      console.log("Data processed. Total items:", data.length);


      setTableData(data);
      setFilteredData(data);
      setIsLoading(false); // Set loading state to false when the data is loaded
    } catch (error) {
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [token, filterIds]);

  const convertToXML = (data) => {
    let xml = '<?xml version="1.0" encoding="UTF-8"?>\n<tableData>\n';
    data.forEach((row) => {
      xml += `  <row>\n`;
      Object.keys(row).forEach((key) => {
        xml += `    <${key}>${row[key]}</${key}>\n`;
      });
      xml += `  </row>\n`;
    });
    xml += "</tableData>";
    return xml;
  };

  const downloadXML = () => {
    const xmlData = convertToXML(tableData);
    const blob = new Blob([xmlData], { type: "application/xml" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = "tableData.xml";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const exportPDF = () => {
    const doc = new jsPDF("landscape");

    doc.setFontSize(12);

    const dataWithConsignee = tableData.map((item) => ({
      ...item,
      consignee: `${item.customerFirstName} ${item.customerLastName} C/O JUNE LOGISTICS`,
    }));

    const exportColumns = columns
      .filter((column) => column.accessorKey !== "action" && column.accessorKey !== "invoice")
      .map((column) => ({
        header: column.header,
        dataKey: column.accessorKey,
      }));

    autoTable(doc, {
      columns: exportColumns,
      body: dataWithConsignee,
      styles: {
        fontSize: 8,
        cellWidth: "wrap",
      },
      columnStyles: {
        id: { cellWidth: 40 },
        shipper: { cellWidth: 60 },
        consignee: { cellWidth: 60 },
      },
      didDrawPage: (data) => {
        doc.text("My Table Data", 20, 10);
      },
    });

    doc.save("tableData.pdf");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  const exportToExcel = () => {
    const dataWithConsignee = tableData.map((item) => ({
      "Date Added": formatDate(item.createdOn),
      "ID": item.junId,
      "Shipper": item.supplierName,
      "Description": item.description,
      "Weight": item.weight,
      "Mailbox": item.customerNumber,
      "Consignee": `${item.customerFirstName} ${item.customerLastName} C/O JUNE LOGISTICS`,
      "Tracking Number": item.trackingNumber,
    }));
  
    const ws = XLSX.utils.json_to_sheet(dataWithConsignee);
  
    // Set the date column to have a date format in Excel
    const dateCol = XLSX.utils.encode_col(0); // Assuming "Date Added" is the first column
    for (let i = 2; i <= dataWithConsignee.length + 1; i++) {
      const cell = ws[`${dateCol}${i}`];
      if (cell && cell.v !== 'Invalid Date') {
        cell.t = 'd';
        cell.v = new Date(cell.v);
        cell.z = 'yyyy-mm-dd';
      }
    }
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "AirwayManifest");
    XLSX.writeFile(wb, "AirwayManifest.xlsx");
  };
  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    if (query === "") {
      setFilteredData(tableData);
    } else {
      setFilteredData(
        tableData.filter((item) =>
          Object.values(item).some((value) =>
            (value !== null && value !== undefined) && value.toString().toLowerCase().includes(query)
          )
        )
      );
    }
  };

  const columns = [
    { accessorKey: "junId", header: "ID", size: 130 },
    {
      accessorKey: "createdOn",
      header: "Date Added",
      size: 150,
      Cell: ({ cell }) => {
        const dateValue = cell.getValue();
        const date = new Date(dateValue);
        if (isNaN(date)) {
          return "Invalid Date";
        }
        return date.toLocaleDateString(); // Format the date as needed
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 200,
      Cell: ({ row }) => {
        const productId = row.original.id;
        const currentStatus = row.original.packageStatus;

        const handleStatusChange = (event) => {
          const selectedStatus = event.target.value;
          const customerNumber = row.original.customerNumber;

          const isConfirmed = window.confirm(`Are you sure you want to send this update to customer: ${customerNumber}?`);

          if (!isConfirmed) {
            return;
          }

          try {
            console.log(`Updating status for product ${productId} to ${selectedStatus}`);
            const response = axios.put(
              "/api/v1/user/warehouse/update/product/status",
              null,
              {
                params: {
                  productId: productId,
                  status: selectedStatus,
                },
                headers: {
                  Authorization: `Bearer ${token}`, // Make sure to include the token
                },
              }
            );
            console.log("Status update response:", response.data);
            fetchData(); // Refresh the data
          } catch (error) {
            console.error("Error updating product status:");
            if (error.response) {
              console.error("Response data:", error.response.data);
              console.error("Response status:", error.response.status);
              console.error("Response headers:", error.response.headers);
            } else if (error.request) {
              console.error("No response received. Request details:", error.request);
            } else {
              console.error("Error message:", error.message);
            }
            console.error("Error config:", error.config);
        
            // Display an error message to the user
            alert(`Failed to update status. Error: ${error.response?.data || error.message}`);
          }
        };

        return (
          <FormControl variant="outlined" size="medium">
            <Select
              value={selectedStatusMap[productId] || currentStatus}
              onChange={handleStatusChange}
              displayEmpty
              inputProps={{ 'aria-label': 'status' }}
            >
              <MenuItem value="CREATED">At Miami</MenuItem>
              <MenuItem value="SENT_OFF">At Miami Airport</MenuItem>
              <MenuItem value="TAKEN_OFF">On flight to Jamaica</MenuItem>
              <MenuItem value="LANDED">At Customs</MenuItem>
              <MenuItem value="TRANSIT_TO_LOCAL_WAREHOUSE">On route to JUNE</MenuItem>
              <MenuItem value="PROCESSING">At JUNE</MenuItem>
              <MenuItem value="READY_FOR_DELIVERY">Available for Delivery</MenuItem>
              <MenuItem value="READY_FOR_PICKUP">Available for Collection at JUNE</MenuItem>
              <MenuItem value="PICKED_UP">Package Collected</MenuItem>
              <MenuItem value="OUT_FOR_DELIVERY">On route for delivery</MenuItem>
              <MenuItem value="DELIVERED">Package Delivered</MenuItem>
              <MenuItem value="DELAYED">Delayed</MenuItem>

            </Select>
          </FormControl>
        );
      },
    },
    { accessorKey: "supplierName", header: "Shipper", size: 130 },
    {
      accessorKey: "description",
      header: "Description",
      size: 200,
      Cell: ({ cell }) => <span>{toTitleCase(cell.getValue())}</span>,
    },
    { accessorKey: "weight", header: "Weight", size: 50 },
    { accessorKey: "customerNumber", header: "Mailbox", size: 150 },
    {
      accessorKey: "consignee",
      header: "Consignee",
      size: 300,
      Cell: ({ row }) => (
        <div>
          {row.original.customerFirstName} {row.original.customerLastName} C/O JUNE LOGISTICS
        </div>
      ),
    },
    {
      accessorKey: "invoice",
      header: "Invoice",
      size: 60,
      Cell: ({ row }) => (
        <div>
          {row.original.preAlert && row.original.preAlert.startsWith("https") ? (
            <a href={row.original.preAlert} target="_blank" rel="noopener noreferrer">
              View
            </a>
          ) : (
            <span>None</span>
          )}
        </div>
      ),
    },
    { accessorKey: "trackingNumber", header: "Tracking Number", size: 200 },
  ];

  const table = useMaterialReactTable({
    columns,
    data: filteredData,
    muiTablePaperProps: {
      sx: {
        marginLeft: 0.9,
        maxHeight: 'calc(100% - 64px)',
        overflow: 'auto',
        marginBottom: '20px',
      },
      elevation: 0,
    },
    muiTableBodyCellProps: {
      sx: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    // paginationDisplayMode: 'pages',
    initialState: {
      pagination: { pageIndex: 0, pageSize: isMobile ? 5 : 30 },
      sorting: [{ id: "dateAdded", desc: true }],
    },
    enableStickyHeader: true,
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#ffffff',
        zIndex: 1,
      },
    },
    muiTableProps: {
      sx: {
        boxShadow: 'none',
        borderRadius: '0px',
      },
    },
  });

  return (
    <Box
      className="grid"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
        boxSizing: 'border-box',
        paddingBottom: '6em',
      }}
    >
      {/* <NavAppManagement /> */}
      <Sidebar/>

      <Box marginTop={2} marginBottom={2} marginLeft={1}>
        <Typography variant="h2" align="center">
          Airway Manifest
        </Typography>
        <TextField
          label="Search for specific products"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth
          sx={{ marginBottom: 2 }}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={handleExportClick}
          endIcon={<ArrowDropDownIcon />}
          sx={{ marginRight: 1, textTransform: 'none', boxShadow: 'none' }}
        >
          Manage Packages
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleExportClose}
          elevation={1}
        >
          <MenuItem onClick={() => { downloadXML(); handleExportClose(); }}>
            Export as XML
          </MenuItem>
          <MenuItem onClick={() => { exportPDF(); handleExportClose(); }}>
            Export as PDF
          </MenuItem>
          <MenuItem onClick={() => { exportToExcel(); handleExportClose(); }}>
            Export as Excel
          </MenuItem>
        </Menu>
      </Box>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress style={{ color: "green" }} />
        </Box>
      ) : (
        <MaterialReactTable table={table} />
      )}
      <BottomBar />
    </Box>
  );
};

export default ViewAllAdminProductTable;
