import React from 'react'
import Logo from "../../../assets/JUNE-LOGO-22-removebg-preview.png";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography'

const ShipmentCard = ({ shipmentId, updatedAt }) => (
  <Box>
    <CardContent className="shipment-card">
    <div className="shipment-info">
      <div className="shipment-id">
        <Typography >SHIPMENT</Typography>
        <Typography >{shipmentId}</Typography>
      </div>
      <div className="shipment-dates">
        <div className="shipment-date">
          <Typography>CreatedAt</Typography>
          <Typography>{new Date(updatedAt).toLocaleString()}</Typography>
        </div>
      </div>
    </div>
    <div className="shipment-logo">
      <img src={Logo} alt="Logo" />{" "}
      {/* Update logo_url_here with actual logo URL */}
    </div>
  </CardContent>

  </Box>

);


export default ShipmentCard