import React from "react";
import "./AllCustomerAccountsInterface.scss";
import Sidebar from "../../sidebar/Sidebar";
import Topbar from "../../topbar/Topbar";
import CustomerAccountsView from "../../customers/CustomerAccountsView/CustomerAccountsView";
import BottomBar from "../bottombar/BottomBar";
import NavAppManagement from "../../../components/NavAppSidebar/NavAppManagement";

function AllCustomerAccountsInterface() {
  return (
    <div className="view_cust">
      {/* <NavAppManagement /> */}
      <Sidebar/>
      <div className="viewCust_container">
        {/* <Topbar /> */}
        <div className="top">{/* <h2> Customers</h2> */}</div>
        <div className="">
          <CustomerAccountsView />
        </div>
        <br />
      </div>
      <BottomBar />
    </div>
  );
}

export default AllCustomerAccountsInterface;
