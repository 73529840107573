import React from "react";
import "./Profile.scss";
import Sidebar from "../sidebar/Sidebar";
import TopBar from "../topbar/TopBar";
import UserInfo from "../../UserAccount/UserInfo";
import BottomBar from "../bottombar/BottomBar";
import NavAppCustomer from "../../../components/NavAppSidebar/NavAppCustomer";


const Profile = () => {
  return (
    <div className="profile">
      <NavAppCustomer />
      <div className="profile_container">
  
        <div className="userInfo">
          <UserInfo />
        </div>
      </div>
      <BottomBar />
    </div>
  );
};

export default Profile;
