import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children, allowedRoles }) => {
  const user = useSelector((state) => state.auth.user);
  const userRole = useSelector((state) => state.auth.role);
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const localUser = localStorage.getItem("user");
    const localUserRole = localStorage.getItem("userRole");

    if (localUser) {
      setIsChecking(false);
    } else {
      console.log("No user found in local storage");
      setIsChecking(false);
    }
  }, []);

  const parseRole = (role) => {
    if (typeof role === 'string') {
      try {
        return JSON.parse(role);
      } catch (e) {
        // If parsing fails, assume it's a simple string role
        return [{ authority: role }];
      }
    }
    return role;
  };

  const localUserRole = localStorage.getItem("userRole");
  const parsedLocalUserRole = parseRole(localUserRole);
  const userRoleArray = parseRole(userRole);
  
  const role =
    userRoleArray?.[0]?.authority || 
    parsedLocalUserRole?.[0]?.authority || 
    userRole || 
    localUserRole;

  if (isChecking) {
    // You might want to return a loading indicator here
    return <div>Loading...</div>;
  }

  // Uncomment this if you want to redirect unauthenticated users to login
  // if (!user && !role) {
  //   return <Navigate to="/login" />;
  // }

  if (allowedRoles && Array.isArray(allowedRoles) && allowedRoles.length > 0) {
    if (!role) {
      return <Navigate to="/unAuthorized" />;
    }

    if (!allowedRoles.includes(role)) {
      return <Navigate to="/unAuthorized" />;
    }
  }

  return children;
};

export default ProtectedRoute;